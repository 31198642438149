import React, { useContext } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import { DialogContext } from "./context/DialogContext";
import Image from "next/image";
import Slide from "@mui/material/Slide";
import useTheme from "@mui/system/useTheme";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import DialogContentText from "@mui/material/DialogContentText";
import DialogContent from "@mui/material/DialogContent";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";
import { InputAdornment } from "@mui/material";
import PublicIcon from "@mui/icons-material/Public";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const buttonGradientStyle = {
  background: "linear-gradient(to right, #e61e4d, #d80665)",
};

const ModalDialog = () => {
  // @ts-ignore
  const { open, setOpen } = useContext(DialogContext);

  // const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const theme = useTheme();

  // @ts-ignore
  return (
    <>
      {/*<Button*/}
      {/*  onClick={setOpen}*/}
      {/*  sx={{*/}
      {/*    display: { xs: "none", md: "block" },*/}
      {/*    border: "1px solid #ddd",*/}
      {/*    minWidth: 90,*/}
      {/*    justifyContent: "space-between",*/}
      {/*    borderRadius: 2,*/}
      {/*    textTransform: "capitalize",*/}
      {/*    py: 1,*/}
      {/*    color: "theme.palette.text.primary",*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <Image*/}
      {/*    src="/icons/faFilter.svg"*/}
      {/*    width="24"*/}
      {/*    height="24"*/}
      {/*    loading="lazy"*/}
      {/*  />{" "}*/}
      {/*  Filters*/}
      {/*</Button>*/}
      <Dialog
        PaperProps={
          {
            style: { borderRadius: 18, padding: '0.5rem 0' },

          }
        }
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        // @tes-ignore
        TransitionComponent={Transition}
        keepMounted
      >
        <DialogTitle
          id="dialog-title"
          my={1}
          sx={{ borderBottom: "1px solid #ddd" }}
        >
          <Stack direction={"row"} justifyContent="space-between">
            <IconButton sx={{ p: 0 }} onClick={handleClose}>
              <Image
                src="/icons/MdOutlineCancel.svg"
                width="24"
                height="24"
                loading="lazy"
              />
            </IconButton>
            <Typography fontWeight={"bold"} fontSize={"large"}>
              Login or sign up
            </Typography>
            <Typography />
          </Stack>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography
              align={"left"}
              my={2}
              fontSize={"large"}
              fontWeight={"bold"}
              color={"black"}
            >
              Welcome
            </Typography>

            <Box component="form" noValidate autoComplete="off">
              <FormControl sx={{ width: "100%" }}>
                <OutlinedInput
                  startAdornment={
                    <InputAdornment position="end">
                      <PublicIcon fontSize='small' sx={{marginRight: 1}}/>
                    </InputAdornment>
                  }
                  sx={{ borderRadius: "8px 8px 0 0" }}
                  placeholder="Country/Region"
                />
              </FormControl>
              <FormControl sx={{ width: "100%" }}>
                <OutlinedInput sx={{ width: "100%" }}
                  startAdornment={
                    <InputAdornment position="end">
                      <PhoneAndroidIcon fontSize='small' sx={{marginRight: 1}} />
                    </InputAdornment>
                  }
                  placeholder="Phone number"
                  sx={{ borderRadius: "0 0 8px 8px" }}
                />
              </FormControl>
            </Box>

            <Typography
              sx={{ fontSize: "0.7rem" }}
              variant={"subtitle2"}
              my={2}
            >
              We’ll call or text you to confirm your number. Standard message
              and data rates apply. Privacy Policy
            </Typography>

            <Box
              sx={{
                display: "flex",
                color: "white",
                height: 50,
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 3,
                cursor: "pointer",
                backgroundColor: buttonGradientStyle,
                "&:hover": {
                  backgroundColor: theme.palette.secondary.main,
                  opacity: [0.9, 0.9, 0.9],
                },
              }}
            >
              <Typography fontSize={16}>
                <b>Continue</b>
              </Typography>
            </Box>
            <Typography my={2} align={"center"}>
              <b>Or</b>
            </Typography>

            <Grid
              sx={{ cursor: "pointer" }}
              container
              direction={"column"}
              gap={1}
            >
              <Paper variant="outlined">
                <Stack direction={"row"} justifyContent="space-between">
                  <IconButton sx={{ p: 0 }} onClick={handleClose}>
                    <Image
                      src="/icons/icons8-facebook-48.png"
                      width="24"
                      height="24"
                      loading="lazy"
                    />
                  </IconButton>
                  <Typography
                    align={"center"}
                    variant={'body2'}
                    fontWeight={"bold"}
                  >
                    Continue with facebook
                  </Typography>
                  <Typography />
                </Stack>
              </Paper>
              <Paper variant="outlined">
                <Stack direction={"row"} justifyContent="space-between">
                  <IconButton sx={{ p: 0 }} onClick={handleClose}>
                    <Image
                      src="/icons/FcGoogle.svg"
                      width="24"
                      height="24"
                      loading="lazy"
                    />
                  </IconButton>
                  <Typography
                    align={"center"}
                    variant={'body2'}
                    fontWeight={"bold"}
                  >
                    Continue with Google
                  </Typography>
                  <Typography />
                </Stack>
              </Paper>
              <Paper variant="outlined">
                <Stack direction={"row"} justifyContent="space-between">
                  <IconButton sx={{ p: 0 }} onClick={handleClose}>
                    <Image
                      src="/icons/AiFillApple.svg"
                      width="24"
                      height="24"
                      loading="lazy"
                    />
                  </IconButton>
                  <Typography
                    align={"center"}
                    variant={'body2'}
                    fontWeight={"bold"}
                  >
                    Continue with Apple
                  </Typography>
                  <Typography />
                </Stack>
              </Paper>
              <Paper variant="outlined">
                <Stack direction={"row"} justifyContent="space-between">
                  <IconButton sx={{ p: 0 }} onClick={handleClose}>
                    <Image
                      src="/icons/AiOutlineMail.svg"
                      width="24"
                      height="24"
                      loading="lazy"
                    />
                  </IconButton>
                  <Typography
                    align={"center"}
                    variant={'body2'}
                    fontWeight={"bold"}
                  >
                    Continue with E-mail
                  </Typography>
                  <Typography />
                </Stack>
              </Paper>
            </Grid>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ModalDialog;
